import React, { useState, useEffect } from "react";
import {
    Box,
    AbsoluteCenter,
    Stack,
    Text,
    Link,
    Flex,
    Button,
} from "@chakra-ui/react";
import CountUp from "react-countup";

export default function Home() {
    const [amount, setAmount] = useState(8461332);

    const getAmount = async () => {
        //
    };

    useEffect(() => {
        getAmount();
    }, []);

    return (
        <Box position="relative" height="100vh">
            <AbsoluteCenter width="100vw" textAlign="center">
                <Stack gap={8} align="center">
                    <Text className="title" lineHeight={0.8}>
                        <CountUp end={amount} duration={3} prefix="$" />
                    </Text>
                    <Text fontSize="xl">
                        outstanding in <u className="orange">bitcoin</u> backed
                        loans
                    </Text>
                    {/* <Text fontSize="xs" color="gray">
                        Humblestacker provides flexible liquidity to everyone
                        without credit checks or traditional barriers.
                    </Text> */}
                    {/* <Flex gap={12} justify="center">
                        <Badge>10% Fixed APR</Badge>
                        <Badge>Flexible Terms</Badge>
                        <Badge>No Fees</Badge>
                    </Flex> */}
                    <Flex>
                        <Link href="mailto:hello@humblestacker.com">
                            <Button variant="outline">Get in touch</Button>
                        </Link>
                    </Flex>
                </Stack>
            </AbsoluteCenter>
        </Box>
    );
}
