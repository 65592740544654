import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./home";
import Footer from "./footer";
import Header from "./header";
import Auth from "./auth";
import { toast } from "react-hot-toast";
import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailLink,
    isSignInWithEmailLink,
} from "firebase/auth";

export default function App() {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        if (isSignInWithEmailLink(auth, window.location.href)) {
            const toastId = toast.loading("Signing in.");
            let email = window.localStorage.getItem("emailForSignIn");
            if (!email) {
                toast.error("Something has gone wrong here.", { id: toastId });
            }
            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    window.localStorage.clear();
                    toast.success("Welcome back.", { id: toastId });
                })
                .catch((error) => {
                    toast.error("Something has gone wrong here.", {
                        id: toastId,
                    });
                });
        }
        onAuthStateChanged(auth, async (user) => {
            setReady(true);
        });
    }, []);

    return (
        ready && (
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/auth" element={<Auth />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        )
    );
}
