import React, { useState, useEffect } from "react";
import { Button, Link, Flex } from "@chakra-ui/react";

export default function Header() {
    return (
        <Flex className="header" align="center" wrap="wrap" gap={4}>
            <Link fontSize="xl" fontWeight="bold" href="/">
                humblestacker
            </Link>
            <Flex gap={6} align="center" wrap="wrap">
                <Link href="/auth">
                    <Button variant="outline">Client</Button>
                </Link>
            </Flex>
        </Flex>
    );
}
