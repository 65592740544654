import React, { useState, useEffect } from "react";
import { Text, Button, Flex, Stack, Input } from "@chakra-ui/react";
import { toast } from "react-hot-toast";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

export default function Auth() {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const onChange = (event) => setEmail(event.target.value);

    const pressed = async (event) => {
        event.preventDefault();
        document.activeElement.blur();
        const trimmed = email.trim();
        if (trimmed.length > 4) {
            setLoading(true);
            const toastId = toast.loading("Sending sign in link.");
            toast.error("Invalid credentials.", { id: toastId });
            setLoading(false);
            return;
            const settings = {
                url:
                    window.location.hostname === "localhost"
                        ? "http://localhost:3000"
                        : "https://coffeechatcc.web.app",
                handleCodeInApp: true,
            };
            try {
                const auth = getAuth();
                await sendSignInLinkToEmail(auth, trimmed, settings);
                window.localStorage.setItem("emailForSignIn", trimmed);
                toast.success("Check your inbox.", { id: toastId });
            } catch (error) {
                toast.error(error.message, { id: toastId });
                setLoading(false);
            }
        }
    };

    return (
        <Stack className="narrow" gap={12}>
            <Stack gap={12}>
                <Text className="title">Sign in</Text>
                <Stack gap={4}>
                    <Input placeholder="Email" onChange={onChange} />
                    <Flex>
                        <Button
                            isLoading={loading}
                            onClick={pressed}
                            variant="outline"
                        >
                            Continue
                        </Button>
                    </Flex>
                </Stack>
            </Stack>
        </Stack>
    );
}
