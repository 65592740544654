import { Text, Flex } from "@chakra-ui/react";

export default function Footer() {
    return (
        <Flex
            wrap="wrap"
            position="fixed"
            bottom="16px"
            justify="space-between"
            left="16px"
            right="16px"
        >
            <Text fontSize="xs" textColor="gray">
                2024 © Satoshi Limited
            </Text>
            {/* <Text fontSize="xs" textColor="gray">
                Loans underwritten by Morgan Stanley (Switzerland) AG
            </Text> */}
        </Flex>
    );
}
